@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

featured-video-thumbnail-carousel {
	.AbsoluteFill();
	display: block;

	vb-flickity-carousel, .flickity-carousel-container {
		height: 100%;

		.dot {
			border-width: 1px;
			border-style: solid;
		}

		.flickity-prev-next-button {
			top: 50% !important;
		}
	}

	.carousel-cell {
		height: 100%;
		width: 100%;
		padding: 0;
	}

	.flickity-page-dots {
		 bottom: 50px;

		.flickity-page-dot.is-selected {
			background-color: var(--theme-primary-txt);
		}

		.flickity-page-dot {
			background-color: var(--theme-primary-txt-fade-50);
		}
	}
}
