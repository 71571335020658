@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

@dialogPadding: 16px;

.root {
	background-color: var(--theme-primary);
	color: var(--theme-primary-txt);
}

.dialogHeader {
	padding: @dialogPadding;
	background-color: var(--theme-primary-overlay);

	button {
		border: none;
		span {
			font-size: smaller;
		}

	}

	:after {
		background-color: var(--theme-primary-overlay) !important;
	}

	span {
		display: inline-flex;
		align-items: center;
		font-size: large;
	}
}

.dialogContent {
	padding: @dialogPadding;
}

.largeRounded {
	padding: 16px;
	color: var(--theme-primary-txt);
	border-style: solid;
	border-radius: 5px;
	border-color: var(--theme-primary);
	background-color: var(--theme-primary);
	margin: 8px;

	span {
		font-size: large;
	}

	div {
		font-size: small;
		margin-top: 8px;
		text-align: left;
	}
}

.selected {
	border-color: var(--theme-accent);
}

.submitContainer {
	display: flex;
	flex-direction: row;
	justify-content: end;

	button {
		margin: 8px;
		padding: 8px 16px;
	}
}

.newPlaylistDialog {
	:global(.modal-content) {
		border-radius: 12px;
		border: none;
		background: none;
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
	}
}
