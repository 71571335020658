@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/utilities';

.theme-TwoTier {
	.navbar-wrapper {
		padding: @type16 @type32;

		@media all and (max-width: @viewPortSmallWidth) {
			padding: @type8;
		}
	}
	.navbar {
		align-items: center;
		justify-content: center;
		min-height: 75px;

		.right-container {
			.vb-icon, .vb-icon:before {
				.flex-center-align;
				width: 1.5em;
				height: 1.5em;
				line-height: 1.5;
			}

			.vb-icon-go-live {
				padding-bottom: 8px;
			}
		}

		.brand-cell {
			@media all and (max-width: @viewPortMedWidth) {
				margin-left: 25px;
				max-width: 100%;
				text-align: center;
			}
		}

		[flex="branding"] {
			.flexItem(0 1 40%);

			@media all and (max-width: @viewPortMedWidth) {
				align-self: center;
				.flexItem(1 1 100%);
			}
		}

		branding-logo img {
			height: 75px;

			@media all and (max-width: @viewPortSmallWidth) {
				height: 49px;
			}

			@media all and (max-width: @viewPortMobileDeviceWidth) {
				height: 35px;
			}
		}

		[flex="search"] {
			.flexItem(1 1 10%);
		}

		&-tier-two {
			align-items: center;
			border-top: 1px solid #2f4f4f;
			justify-content: center;
			padding: @type8;

			@media all and (max-width: @viewPortMedWidth) {
				display: none !important;
			}

			.menu-list-tight {
				list-style-type: none;
				margin: 0;
				padding: 0 0 0 50px;

				.logout-help-container {
					display: flex;
					gap: 2px;

					a {
						margin-top: 1px;
					}
				}
			}

			.pip {
				display: none;
			}

			help-menu {
				display: inline;

				.dropdown-toggle {
					white-space: nowrap;
					margin-right: -4px;
				}

				.help-tray {
					padding: 0 !important;
				}

				.dropdown-menu {
					top: 34px !important;
					text-transform: none !important;
				}
			}

			.event-title-container {
				.displayFlex();
				.flexJustifyContent(center);
			}

			.event-display {
				.alignItems(center);
				.displayFlex;

				&-link {
					max-width: 230px;
					text-align: left;
					width: 230px;
					.TextClip;
				}

				&-text {
					margin: 0;
				}
			}
		}

	}

	div.search-cell search-header {
		vb-ui-typeahead-angular input {
			background: transparent;
			border: 1px solid transparent;
			padding-left: 8px;
			.BoxShadow(none);

			&:focus {
				border-color: transparent !important;
				.BoxShadow(none) !important;

				.Placeholder({
					color: transparent !important;
				});
			}
		}

		vb-ui-typeahead-angular {
			@media all and (max-width: @viewPortMedWidth - 1) {
				display: none;
			}
		}

		vb-ui-typeahead-angular .dropdown-menu {
			right: -48px;
			left: 0;
			width: auto;
			top: 16px !important;
			li > a {
				padding: 8px;
			}
		}

		.btn.theme-accent-btn:hover {
			background: transparent !important;
		}
	}

	.main-nav-btn {
		.BoxShadow(none);
		font-size: @type24;
		.RoundedShape(0);

		&:not(.theme-accent-bg) {
			background: transparent;
			min-width: 40px !important;
		}
	}

	.open .dropdown-toggle {
		&:before {
			content: " ";
			position: absolute;
			.TriangleTop(14px, @black);
			left: 50%;
			margin-left: -14px;
			bottom: -18px;
			z-index: 900;
		}
	}

	.dropdown-menu:not(.upload-child-dropdown-menu) {
		line-height: 1;
		left: auto;
		top: 54px !important;
		margin: 0;
		padding: 0;
		background: @color2Lighten05;
		border: 1px solid @blackFade50;
		border-top: 0;
		.RoundedShape();
		.BoxShadow(0 10px 20px @blackFade50);
		z-index: 5000;

		@media all and (max-width: @viewPortSmallWidth) {
			top: 77px !important;
		}

		@media all and (min-width: (@viewPortSmallWidth + 1)) and (max-width: (@viewPortMedWidth - 1)) {
			top: 47px !important;
		}

		.modal-open & {
			z-index: 910;
		}

		&:not(.notification-menu):not(.upload-media) :not(.typeahead-match-active) {
			a,
			.list-group-item {
				background: none;
				color: @color1MedText;
				border: none;
				white-space: inherit;

				.glyphicons {
					color: @color1LightText;
					font-size: @type16;
				}
			}

			a {
				color: inherit;
			}
		}

	}

	.align-right .dropdown-menu {
		right: 0;
	}

	.media-menu-list-item {
		position: relative;

		.dropdown-menu {
			left: 0 !important;
			position: absolute;
			top: 28px !important;

			.media-arrow-bg,
			.media-arrow-fg {
				left: auto;
				right: 225px;
			}
			.media-arrow-bg {
				left: auto;
				right: 223px;
			}
		}

		.dropdown-toggle:before,
		.dropdown-toggle:after {
			opacity: 0;
		}
	}

	media-menu {
		.arrow-container {
			left: 215px;
			top: -37px
		}
	}

	/* will have to think about how to manage utilty names better for these situation */
	@media all and (max-width: @viewPortVerySmallWidth) {
		.xs-mbl-left-30 {
			margin-left: 30px !important;
			margin-right: 0 !important;
		}
	}

	notifications-menu-button .notifications.theme-accent-btn:hover,
	go-live .go-live.theme-accent-btn:hover {
		background: transparent !important;
	}

	/* Mobile menu */

	mobile-menu {
		.mobile-menu {

			vb-ui-typeahead-angular .dropdown-menu {
				top: 0 !important;
			}

			.input-group {
				display: inline-table;
				border-bottom: none;
			}
		}
	}
}
