@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

featured-video-thumbnail-grid {
	display: block;
	.AbsoluteFill();

	.outer-layout {
		height: 100%;
	}

	featured-video-thumbnail {
		height: auto;

		.thumbnail-content .btn-container {
			justify-content: center;
		}

	}

	.quarter-grid featured-video-thumbnail:nth-child(2):last-child {
		.flexBasis(100%);
	}
}
