@import "~rev-less/global/variables";

help-menu, help-menu-mobile {
	display: block;

	.help-menu-list {
		background-color: var(--theme-accent) !important;
	}

	.dropdown-toggle {
		&::before {
			content: none !important;
		}
	}

	.help-tray .vb-icon-help {
		margin-top: 4px;
	}

	.help-menu-button .active {
		background-color: var(--theme-accent) !important;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}

	.label-container {
		flex-grow: 1;
	}

	a {
		margin-top: 2px;
	}

	a:hover {
		text-decoration: none;
	}

	.help-nav {
		margin: 0;
		padding: 8px !important;
		max-height: 300px;
		overflow-y: auto;
		border-radius: 16px !important;
		width: max-content;
		margin-top: 10px !important;

		&-link {
			margin: 0;
			/* override specificity from .navbar */
			padding: 10px 12px !important;
			border-radius: 12px;

			&:hover {
				background-color: var(--theme-header-overlay) !important;
			}
		}
		.divider {
			margin: 0;
			height: 1px;
			overflow: hidden;
			background-color: var(--theme-header-txt);
		}
	}
	a.main-nav-btn {
		display: inline-block;
		padding: 13px 12px;
		line-height: 1.4;
		position: relative;
	}
}

