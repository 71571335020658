@import '~rev-less/global/variables';
@import '~rev-less/global/mixins';

.right-sidebar .right-sidebar-content .filters-sidebar {

	tags-input.category-tags-input {
		.category-item,
		.category-autocomplete-item {
			font-size: 13px;

			> span,
			> small {
				&:extend(.TextClip);
				display: block;
				padding-right: .5rem;
			}

			> span {
				font-weight: bold;
				line-height: 22px;
			}

			> small {
				width: 100%;
				line-height: 12px;
			}
		}

		.tags .tag-item {
			background: #d7f2ff;
			color: @darkBlue;
			text-shadow: 0 1px 0 @whiteFade50;

			border: 1px solid @blackFade25;
			height: auto !important;
			position: relative;
			padding: .5rem 2rem .5rem .75rem;
			width: 48%;

			.remove-button {
				position: absolute;
				top: 0;
				right: 0;
				width: 30px;
				color: inherit;
				text-align: center;
				height: 100%;
				border-left: 1px solid rgba(0,90,130,0.1);
				font-size: 11px;
				padding-top: 12px;
				color: rgba(0,90,130,0.25);

				&:hover {
					text-decoration: none;
					background: rgba(0,90,130,0.2);
					color: @darkBlue;
				}
			}
		}
	}
}

.clear-btn-wrapper {
	margin-top: 20px;
	text-align: right;
}

.filters-sidebar {
	.control-label {
		color: var(--theme-primary-txt) !important;
	}
}

search-filters-sidebar {
	@media (min-width: @viewPortMedWidth) {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
}
