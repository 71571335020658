@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.goLive {
	width: auto;
	display: block;
	border: 1px solid var(--theme-header-txt);
}

.goLiveMenu {
	@media (max-width: @viewPortSmallWidth) {
		width: 100%;
		position: fixed;
	}
}

.goLiveDropDown {
	width: 300px;
	padding: 10px 20px;

	@media(max-width: @viewPortSmallWidth) {
		width: auto;
	}

	> div {
		padding-right: 10px;
	}
}

.goLiveForm {
	height: 300px;
	padding-right: 10px;
	overflow-y: auto;
	margin-right: -20px;
}

.templatesToggle {
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
}

button :global(.vb-icon-go-live) {
	position: relative;
	top: 3.5px;
}
