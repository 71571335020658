@import (reference)'~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

vb-search-filter-list {
	display: flex;
	margin-bottom: 10px;
	max-width: 90vw;

	.search-filter-item {
		display: inline-block;
		margin: 0 5px 4px 0;
		padding: 2px 10px 5px;
		.RoundedShape(3px);
		border: 1px solid var(--theme-accent-txt);
		color: var(--theme-accent-txt);
		background: var(--theme-accent);

		button.close{
			margin-left: 10px;
			position: relative;
			top: 1px;
			color: var(--theme-accent-txt);
			background: none;
			border: none;
			font-size: 21px;
		}
	}

}

@media (max-width: (@viewPortSmallWidth - 1)) {
	vb-search-filter-list{
		display: none;
	}
}
