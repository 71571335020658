@import "~rev-less/global/variables";

bulk-edit-videos {
	@media (min-width: @viewPortMedWidth) {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	video-expiration {
		.col-lg-9 {
			width: 100%;
		}
	}

	tags-input {
		color: @black
	}

	.vb-ui-form-group-control {
		&:after {
			top: 25px;
			right: -13px;
		}
	}
}

.sidebar-form {
	padding: 10px 15px;
	overflow-y: auto;
}

.transcription-metadata-form {
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	gap: 15px;
	padding: 20px 15px !important;

	.transcribeField {
		display: flex !important;
		align-items: center;

		.selectLanguage {
			padding: 0 !important;
			margin-left: 10px;
			margin-bottom: 5px;
		}
	}

	.generateBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		width: 100%;
		border: 1px solid var(--theme-accent) !important;
		padding-bottom: 12.5px;
		padding-top: 12.5px;
		margin-top: 5px;

		&:hover {
			border: 1px solid var(--theme-accent) !important;
		}

		&:focus {
			outline: auto !important;
		}
	}
}

.bulk-edit-sidebar-btns {
	border-top: 1px solid var(--theme-primary-txt) !important;
	border-bottom: 1px solid var(--theme-primary-txt) !important;
	border-left: 1px solid var(--theme-primary-txt) !important;

	@media(max-width: 991px) {
		display: flex;
		border-right: 1px solid var(--theme-primary-txt) !important;
	}
}

.bulk-edit-tags {
	background-color: @white;
}
