@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.form {
	padding: 10px 20px;

	vb-ui-form-group {
		display: block;
		margin-bottom: 10px;
	}
}

.columnContainer {
	display: flex;
	justify-content: stretch;
	.flexWrap(nowrap);
	margin-top: 50px;

	@media all and (max-width: @viewPortLargeWidth - 1) {
		margin-top: 0;
		padding: 0 25px;
	}
}

.columnLeft {
	padding-right: 2rem;
	border-width: 0 1px 0 0;
	border-style: solid;
}

.columnRight {
	padding-left: 2rem;
}

.profilePicture{
	img {
		max-height: 200px !important;
	}
}

.loadingSpinner {
	.AbsoluteFill;
}

.tabContentsContainer {
	width: 100%;
}

.tabsWrapper {

	vb-ui-tab {
		display: contents;
	}

	[role="tabpanel"] {
		width: 100%;
		overflow: hidden;
		display: flex;

		user-profile-basic-settings,
		vb-mfa-authentication {
			width: 100%;
		}
	}

	> :global(.nav-tabs) {
		border-bottom: transparent;

		> li {
			> a {
				.TextClip;
				&:hover {
					border-bottom-color: transparent;
				}
			}

			&:not(:global(.active)) > a {
				background-color: #6d6d6d;
				bottom: 0;
				color: @white;
				padding: 9.5px 15px;

				@media all and (max-width: 371px) {
					padding: 9.5px 5px;
				}

				@media all and (max-width: 1334px) {
					.TextClip;
				}
			}

			@media all and (max-width: 1334px) {
				max-width: 50%;
			}
		}
	}

	> :global(.tab-content) {
		border: 1px solid transparent;
		.RoundedShape();
		border-top-left-radius: 0;

		margin-bottom: 20px;
		padding: 20px 10px;
	}
}

.profileInProgress {
	display: flex;
	vb-loading-spinner {
		align-self: center;
		margin-right: 5px;
	}
}

@media all and (max-width: @viewPortLargeWidth - 1) {
	.columnContainer {
		.flexWrap(wrap);
	}

	.columnLeft,
	.columnRight {
		flex: 100%;
	}

	.columnLeft {
		border-width: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.columnRight {
		margin-top: 30px;
		padding-left: 0;
	}
}
