user-nav-menu {
	white-space: nowrap;

	.vb-icon {
		margin-right: 4px;
	}
}

.logout-tray:hover {
	text-decoration: underline !important;
}
