@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/utilities';

@classicGlyphiconsSize: @type18;
@classicControlHeight: 36px;
@classicControlMinWidth: 40px;
@navBarHeight: 57px;

.theme-Classic {
	font-size: @type14;

	nav {
		@media all and (max-width: @viewPortMedWidth - 1) {
			min-height: 53px;
			position: relative;
		}
	}

	.brand-cell {
		text-align: center;

		@media all and (max-width: @viewPortMedWidth - 1) {
			position: absolute;
			left: 0;
			right: 0;
		}

		@media(max-width: @viewPortMobileDeviceWidth) {
			right: 30% !important;
		}

		.navbar-brand {
			display: inline-block;
			height: auto;
			float: none;
			margin-top: 0;
			padding: 0;

			@media(max-width: @viewPortMedWidth) {
				float: left;
				margin-left: 128px;
			}

			@media(max-width: @viewPortMobileDeviceWidth) {
				margin-left: 64px;
			}

			img {
				height: 34px;

				@media all and (max-width: @viewPortMedWidth - 1) {
					height: 36px;
				}

				@media(max-width: @viewPortMobileDeviceWidth) {
					height: 25px !important;
				}
			}
		}
	}

	.main-nav-btn {
		font-size: @type14;
		height: @classicControlHeight;
		top: 1px;
		color: var(--theme-header-txt) !important;

		&:not(.themedSearchButton) {
			background-color: var(--theme-header) !important;
			box-shadow: none !important;
			border: none;
			min-width: @classicControlMinWidth !important;

			&:hover {
				background-color: var(--theme-header) !important;
			}

			.glyphicons {
				color: var(--theme-header-txt) !important;
			}

			&::after {
				background-color: transparent;
			}
		}

		.glyphicons {
			font-size: @classicGlyphiconsSize;
			top: 0;
			vertical-align: middle;
		}

		.vb-icon {
			.flex-center-align;
			width: 1rem;
			height: 1rem;
			font-size: 1rem;
		}
	}

	.main-nav-links {
		position: relative;

		> a,
		> button {
			display: inline-block;
			margin-right: 30px;
			height: @navBarHeight;
			line-height: @navBarHeight;
			text-shadow: none;

			&:hover {
				text-decoration: none;
			}
		}

		> .pip {
			height: 7px;
			width: 65%;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		.glyphicons:not(vb-caret) {
			font-size: @classicGlyphiconsSize;
			top: 0;
			vertical-align: middle;
		}

		&:not(.active):not(:hover) > .pip {
			background-color: transparent !important;
		}
	}

	.search-cell vb-ui-typeahead-angular input {
		top: 1px;
		position: relative;
		background-color: var(--theme-header) !important;
		border: none;
		border-radius: 0;
		border-bottom:  1px solid var(--theme-header-txt) !important;
		border-right-style: none;
		padding-bottom: 6px;
		padding-left: 0;

		&:focus {
			border-width: 1px;
			box-shadow: none !important;
		}
	}

	.utility-section {
		margin: 0 2px;
	}

	notifications-menu-button {
		margin-top: 3px !important;
	}

	notifications-menu-button .main-nav-btn.notifications {
		font-size: @type14;
	}

	.medium-count-bubble {
		margin-right: 5px;
	}

	.large-count-bubble {
		margin-right: 15px;
	}

	.upload {
		position: relative;

		.glyphicons {
			z-index: 3;
		}

		&-cell {
			.dropdown-menu {
				@media all and (max-width: @viewPortSmallWidth) {
					top: 70px !important;
				}
			}
		}
	}

	go-live {
		.go-live-menu {
			@media(max-width: @viewPortSmallWidth) {
				top: 66px !important;
			}
		}

	}

	.upload-cell {
		margin-top: 3px;
	}

	go-live button, .upload-cell .upload {
		background-color: var(--theme-header) !important;
		color: var(--theme-header-txt) !important;
		border: none;
		box-shadow: none !important;
	}

	.open .dropdown-toggle {
		&:before {
			content: " ";
			position: absolute;
			.TriangleTop(14px, @black);
			left: 50%;
			margin-left: -14px;
			bottom: -17px;
			@media all and (max-width: @viewPortSmallWidth - 1) {
				bottom: -22px;
			}
			z-index: 5001;
		}
	}

	.user-profile button {
		margin-top: 0;
		display: flex;
		align-items: center;
		-webkit-align-items: center;
	}

	.admin-btn {
		margin-top: 6px;

		&:hover {
			text-decoration: none;
		}
	}

	vb-profile-picture {
		margin-right: 8px;
	}

	.dropdown-menu {
		line-height: 1;
		margin: 0;
		padding: 0;
		background: @color2Lighten05;
		.RoundedShape();
		z-index: 5000;

		&:not(typeahead-container, .upload-child-dropdown-menu) {
			top: 54px !important;
		}

		.modal-open & {
			z-index: 910;
		}

		.list-group {
			border-radius: 0;
		}
	}

	.dropdown-menu:not(.upload-media) :not(.typeahead-match-active) {
		a,
		.list-group-item {
			background: none;
			border: none;
			white-space: inherit;
			margin-bottom: 0;

			.glyphicons {
				font-size: @type16;
			}
		}
	}

	.dropdown-align-left {
		.dropdown-menu {
			left: 8px;
			right: auto;
		}

		&.open .dropdown-toggle {
			&:before,
			&:after {
				left: 23px;
			}
		}
	}

	.align-right .dropdown-menu {
		right: 0;
	}

	media-menu {
		@media all and (max-width: 75em) {
			&.dropdown-menu {
				left: -32px !important;
			}
			.media-arrow-bg {
				left: 90px;
			}
			.media-arrow-fg {
				left: 92px;
			}
		}
	}

	media-menu-dropdown .open .dropdown-toggle:before {
		display: none;
	}

	vb-caret {
		font-size: @type12;
	}

	help-menu .help-nav {
		top: 59px;
	}

	.user-profile-menu {
		min-width: 0;
	}

	@media(max-width: @viewPortSmallWidth){
		.notification-menu,
		.upload-media {
			top: 65px !important;
		}
	}

	/* needed to offset a negative margin of 4px added in upload-menu.less */
	@media all and (min-width: (@viewPortSmallWidth + 1)) and (max-width: @viewPortMedWidth) {
		.upload-media {
			top: 58px !important;
		}
	}
}
