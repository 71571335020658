@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/utilities';

@sidebarWidth: 48px;
@wrapperMargin: 20px;
@sidebarAtBottom: 991px;

.mediaToolbar {
	background: var(--theme-primary);
	color: var(--theme-primary-txt);
	border-bottom: 1px solid var(--theme-primary-txt-fade-50);
	display: block;
	position: fixed;
	min-height: 53px;
	width: 100%;
	z-index: 10;

	:global(.vb-icon-notification) {
		top: 2px;
		position: relative;
	}
}

.mediaToolbarWrapper {
	margin-top: 7px !important;
	margin-bottom: 7px !important;
	margin-left: @wrapperMargin;
	padding-right: calc(@sidebarWidth + @wrapperMargin);

	@media (max-width: @sidebarAtBottom) {
		padding-right: 35px;
	}
}

.mediaToolbarContainer {
	height: 67px !important;
	display: flex;
	align-items: stretch;
	flex-direction: column;

	@media (min-width: @viewPortSmallWidth) {
		flex-direction: row;
	}

	@media (min-width: @viewPortSmallWidth) {
		height: 37px !important;
	}
}

.mediaToolbarNav {
	display: flex;
	margin-right: 10px;
	flex-grow: 1;
	font-size: @type20;
	line-height: 2rem;
	align-self: flex-start;

	:global(.chevron-right) {
		font-size: @type16;
	}

	@media (min-width: @viewPortSmallWidth) {
		align-self: center;
	}
}

.mediaToolbarControls {
	flex-grow: 0;
	flex-shrink: 0;
	text-align: right;
}

.btn,
.btnGroup :global(.btn) {
	height: 36px;
	padding: 0 @typeUnit3-4x !important;
	.RoundedShape(2px);
	background: none;
	border-color: @borderColor;
	color: @color1MedText;
	.BoxShadow(inset 0 1px 0 @white);
	font-size: @type14;
	text-shadow: none;
	line-height: 36px;

	&:global(.active) {
		&, &:hover {
			&:extend(.BtnWhiteActive);
		}
	}

	&:hover:not(:global(.disabled)) {
		border: 1px solid @color1Lighten50;
	}

	@media (max-width: (@viewPortSmallWidth - 1px)) {
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

.mediaToolbarControlBtns {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;

	&:empty {
		padding-top: 0;
	}

	:global(.badge) {
		background-color: @accentColor;
		left: 4px;
	}

	:global(.btn-group) {
		line-height: 36px;
	}

	@media (min-width: @viewPortSmallWidth) {
		align-items: center;
		justify-content: flex-end;
	}
}

.playAllBtn {
	display: flex;
	gap: 4px;

	.playIcon {
		.flex-center-align;
		width: 16px;
		height: 16px;
		font-size: 1.1rem;
		top: 2px;
		position: relative;
	}
}


.teamHeader {
	border-top: 1px solid @color2;
	padding: 1rem 0 1rem 2rem;
	height: 125px;
	min-width: 100%;
	z-index: 15;
	background-color: @backgroundLighten03;

	@media all and (max-width: @viewPortMedWidth) {
		position: relative;
		top: 0;
	}

	img {
		max-width: 100%;
		max-height: 100px;
	}
}

.uploadToTeam {
	position: absolute;
	top: 0;
	right: 0;
	height: 124px;
	width: 8rem;
	padding: 0;
	background: @blackFade50;

	:global(.upload-btn) {
		height: inherit;
		width: inherit;

		background: none;
		border-color: @borderColor;
		color: @color1MedText;
		font-size: @type14;
		text-shadow: none;
		padding: 0 @typeUnit3-4x !important;
		line-height: 36px;
	}

	:global(.disk_open) {
		color: @white;
		font-size: 2rem;
	}

	:global(.upload-text) {
		font-size: .7rem;
		color: @white;
	}
}

.teamLogoContainer {
	max-width: 120px;
	align-self: flex-start;

	@media (max-width: @viewPortSmallWidth) {
		padding-left: 0 !important;
	}
}

.teamHeaderContent {
	@media (max-width: @viewPortMobileDeviceWidth) {
		padding: 0 !important;
	}
}

.teamName {
	line-height: 1.2;
	margin: 0 0 .5% 0;
	word-break: break-all;
}

.verticalDivider {
	border-width: 0;
	border-left: 2px solid !important;
	margin-left: 5px;
	padding-left: 7px;
}

.toolbarAnchor {
	text-decoration: none;
	&:hover{
		color: var(--theme-primary-txt);
	}
	&:visited {
		color: var(--theme-primary-txt);
	}
}
